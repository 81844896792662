.App {
  text-align: center;
}
/* //all color */
.white{
  color: white;
}
.green{
  color: '#A7D129' !important
}
/* //all font size define here */
.heading{
font-size: 4rem;
}
.subHeadingText{
  font-size:2.4rem;
}
.paraText{
  font-size:1.2rem;
}

.horizontalScroll{
  overflow-x: scroll;
}

::-webkit-scrollbar {
  display: none;
}
.cardText{
  color:black;
}
.card:hover{
  background-color:#e4f7f5;
  color:black;
}

.bgDark{
  background:black;
}
@media screen and (max-width: 768px) {
  .heading{
    font-size: 2.3rem;
    }
  .subHeadingText{
    font-size: 1.7rem;
  }
  .paraText{
    font-size:1.3rem;
  }
}